import { useEffect } from 'react';

import { Row, Col, Container } from 'react-bootstrap';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';

// UI Kit & Components
import Button from 'ui-kit/button/button';
import Link from 'ui-kit/link/link';
import { GOV_INSURANCE_SCHEMA } from 'schema/membership/gov-insurance.schema';
import { GovInsuranceFormValues } from 'types/membership';

// styles
import './membership-gov-insurance-form.styles.scss';

// hooks
import { useGlobalLink } from 'hooks/useGlobalLink';

export interface GovInsuranceFormProps {
    initialValues: Partial<GovInsuranceFormValues>;
    onSubmit: (values: Partial<GovInsuranceFormValues>) => void;
}

const MembershipGovInsuranceForm = ({ onSubmit, initialValues }: GovInsuranceFormProps) => {
    const { t } = useTranslation();

    const formName = 'Registration - Gov Insurance';
    const globalLink = useGlobalLink();

    useEffect(() => {
        globalLink.setFormName(formName);
        globalLink.setFlowName('MembershipGovInsurance');
        globalLink.setStepName(t('membership.govInsurance.profileTitle'));
    }, [globalLink, t]);

    return (
        <Container fluid>
            <Row>
                <Col>
                    <Formik onSubmit={onSubmit} validationSchema={GOV_INSURANCE_SCHEMA} initialValues={initialValues}>
                        {({ errors, touched, handleSubmit, isSubmitting, values }) => (
                            <Form
                                id="registration-form"
                                data-ga-form-name={formName}
                                onSubmit={handleSubmit}
                                autoComplete="off"
                                className="membership-gov-insurance-form"
                            >
                                <div className="membership-group-form-type">
                                    <div className="membership-group-form-type--field-label" id="radio-item-group">
                                        {t('membership.govInsurance.form.alreadyEnrolled')}
                                    </div>
                                    {errors?.enrollment && touched?.enrollment && (
                                        <div className="membership-group-form-type--error">
                                            <ErrorMessage name="enrollment">
                                                {() => (
                                                    <div>
                                                        {t('membership.govInsurance.form.errors.enrollment.required')}
                                                    </div>
                                                )}
                                            </ErrorMessage>
                                        </div>
                                    )}
                                    <div
                                        className={`radio-items ${errors?.enrollment ? 'has-errors' : ''}`}
                                        role="group"
                                        aria-labelledby="radio-item-group"
                                    >
                                        <label className="radio-toggle--label border" htmlFor="enrollmentChoiceYes">
                                            <Field
                                                type="radio"
                                                name="enrollment"
                                                value="Yes"
                                                className="radio-toggle--input"
                                                id="enrollmentChoiceYes"
                                            />
                                            Yes
                                        </label>
                                        <label className="radio-toggle--label border" htmlFor="enrollmentChoiceNo">
                                            <Field
                                                type="radio"
                                                name="enrollment"
                                                value="No"
                                                className="radio-toggle--input"
                                                id="enrollmentChoiceNo"
                                            />
                                            No
                                        </label>
                                    </div>
                                </div>
                                <Row>
                                    <Col className="d-flex flex-column align-items-center mt-4">
                                        <Button
                                            async
                                            label={t('membership.govInsurance.form.buttons.submit')}
                                            type="submit"
                                            variant="primary"
                                            className="btn-gov-insurance-submit"
                                        />
                                        <Link
                                            to={'/get-started/membership?redirect=/membership/gov-insurance'}
                                            label={t('membership.govInsurance.form.backLink')}
                                            dataGALocation={'membership'}
                                            className="sm-full text-center mt-4 btn-back-link"
                                            variant="subtle"
                                            external={false}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </Container>
    );
};

export default MembershipGovInsuranceForm;
