import { TFunction } from 'gatsby-plugin-react-i18next';
import { Col, Container, Row } from 'react-bootstrap';

// styles
import './gov-insurance-modals.style.scss';

export interface showGovInsuranceModalContentProps {
    translation: TFunction<'translation'>;
    onContinueClick?: () => void;
}

const ShowGovInsuranceModalContent = ({ translation }: showGovInsuranceModalContentProps) => {
    return (
        <Container className="modal-content border-0 pr-4 pl-4 text-center gov-insurance-modals" fluid>
            <Row>
                <Col className="modal-body">
                    <p>{translation('modals.membership.govInsurance.warning.body')}</p>
                </Col>
            </Row>
        </Container>
    );
};
export default ShowGovInsuranceModalContent;
